import { useState } from "react";
import { NavLink } from "react-router-dom";
import { AlignRightOutlined } from "@ant-design/icons";

const Graphs = () => {

    const [toggle, setToggle] = useState(false);
    const handleToggle = () => {
    if (toggle === false) {
        setToggle(true);
    } else {
        setToggle(false);
    }
    }

    return (
        <div className="graphPage">
            <nav className={toggle ? "menuOpen":"menuClosed"}>
                <div className={toggle ? 'logoHidden':'logoText'}>NBA Point Inflation</div>
                <ul className={toggle ? "liOpen":"liClosed"}>
                    <li><NavLink to='/topScorers' onClick={()=>handleToggle()}>Check Top Scorers</NavLink></li>
                    {/* <li><NavLink to='/graphs' onClick={()=>handleToggle()}>Get Graphs</NavLink></li> */}
                    <li><NavLink to='/' onClick={()=>handleToggle()}>Calculator</NavLink></li>
                </ul>
                <div className='toggle' onClick={handleToggle}><AlignRightOutlined/></div>
            </nav>

        </div>
    )
}

export default Graphs;