export const data = {
    "2023-24": 114.2,
    "2022-23": 114.7,
    "2021-22": 110.6,
    "2020-21": 112.1,
    "2019-20": 118.8,
    "2018-19": 111.2,
    "2017-18": 106.3,
    "2016-17": 105.6,
    "2015-16": 102.7,
    "2014-15": 100.0,
    "2013-14": 101.0,
    "2012-13": 98.1,
    "2011-12": 96.3,
    "2010-11": 99.6,
    "2009-10": 100.4,
    "2008-09": 100.0,
    "2007-08": 99.9,
    "2006-07": 98.7,
    "2005-06": 97.0,
    "2004-05": 97.2,
    "2003-04": 93.4,
    "2002-03": 95.1,
    "2001-02": 95.5,
    "2000-01": 94.8,
    "1999-00": 97.5,
    "1998-99": 91.6,
    "1997-98": 95.6,
    "1996-97": 96.9,
    "1995-96": 99.5,
    "1994-95": 101.4,
    "1993-94": 101.5,
    "1992-93": 105.3,
    "1991-92": 105.3,
    "1990-91": 106.3,
    "1989-90": 107.0,
    "1988-89": 109.2,
    "1987-88": 108.2, 
    "1986-87": 109.9, 
    "1985-86": 110.9, 
    "1984-85": 110.8, 
    "1983-84": 110.1, 
    "1982-83": 108.5, 
    "1981-82": 108.6, 
    "1980-81": 108.1, 
    "1979-80": 109.3, 
  }
