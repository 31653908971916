import { InputNumber, Select, Button } from 'antd';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AlignRightOutlined } from '@ant-design/icons';
import { data } from '../data.js';

const Calculator = () => {

      // Current States
      const [playerPoints, setPlayerPoints] = useState(0);
      const [yearOfPlayer, setYearOfPlayer] = useState("2023-24");
      const [yearToCompare, setYearToCompare] = useState("2023-24");
      const [inflatedValue, setInflatedValue] = useState();
      
      // State Functions
      const handlePlayerPoints = (value) => {
        setPlayerPoints(value);
      }
    
      const handleYearOfPlayer = (value) => {
        setYearOfPlayer(value);
      }
    
      const handleYearToCompare = (value) => {
        setYearToCompare(value);
      }
    
      const calculateData = (year1, points, year2) => {
        setInflatedValue((points/data[year1]) * data[year2]);
      }

      const [toggle, setToggle] = useState(false);
      const handleToggle = () => {
        if (toggle === false) {
          setToggle(true);
        } else {
          setToggle(false);
        }
      }
    
      return (
        <div className="App">
          <nav className={toggle ? "menuOpen":"menuClosed"}>
            <div className={toggle ? 'logoHidden':'logoText'}>NBA Point Inflation</div>
            <ul className={toggle ? "liOpen":"liClosed"}>
              <li><NavLink to='/topScorers' onClick={()=>handleToggle()}>Check Top Scorers</NavLink></li>
              {/* <li><NavLink to='/graphs' onClick={()=>handleToggle()}>Get Graphs</NavLink></li> */}
              <li><NavLink to='/' onClick={()=>handleToggle()}>Calculator</NavLink></li>
            </ul>
            <div className='toggle' onClick={() => handleToggle()}><AlignRightOutlined/></div>
          </nav>
          <h1>Point Inflation Calculator</h1>
          <div className='playerInfo'>
            <div className='playerInput'>
              <h3>Player Points per Game</h3>
              <InputNumber size="large" min={0} max={50} defaultValue={0} onChange={handlePlayerPoints}/>
            </div>
            <div className='playerInput'>
              <h3>Year Player Played</h3>
              <Select
                defaultValue="2023-24"
                style={{
                  width: 120,
                }}
                onChange={handleYearOfPlayer}
                options={[
                  {
                    value: '2023-24',
                    label: '2023-24',
                  },
                  {
                    value: '2022-23',
                    label: '2022-23',
                  },
                  {
                    value: '2021-22',
                    label: '2021-22',
                  },
                  {
                    value: '2020-21',
                    label: '2020-21'
                  },
                  {
                    value: '2019-20',
                    label: '2019-20'
                  },
                  {
                    value: '2018-19',
                    label: '2018-19'
                  },
                  {
                    value: '2017-18',
                    label: '2017-18'
                  },
                  {
                    value: '2016-17',
                    label: '2016-17'
                  },
                  {
                    value: '2015-16',
                    label: '2015-16'
                  },
                  {
                    value: '2014-15',
                    label: '2014-15'
                  },
                  {
                    value: '2013-14',
                    label: '2013-14'
                  },
                  {
                    value: '2012-13',
                    label: '2012-13'
                  },
                  {
                    value: '2011-12',
                    label: '2011-12'
                  },
                  {
                    value: '2010-11',
                    label: '2010-11'
                  },
                  {
                    value: '2009-10',
                    label: '2009-10'
                  },
                  {
                    value: '2008-09',
                    label: '2008-09'
                  },
                  {
                    value: '2007-08',
                    label: '2007-08'
                  },
                  {
                    value: '2006-07',
                    label: '2006-07'
                  },
                  {
                    value: '2005-06',
                    label: '2005-06'
                  },
                  {
                    value: '2004-05',
                    label: '2004-05'
                  },
                  {
                    value: '2003-04',
                    label: '2003-04'
                  },
                  {
                    value: '2002-03',
                    label: '2002-03'
                  },
                  {
                    value: '2001-02',
                    label: '2001-02'
                  },
                  {
                    value: '2000-01',
                    label: '2000-01'
                  },
                  {
                    value: '1999-00',
                    label: '1999-00'
                  },
                  {
                    value: '1998-99',
                    label: '1998-99'
                  },
                  {
                    value: '1997-98',
                    label: '1997-98'
                  },
                  {
                    value: '1996-97',
                    label: '1996-97'
                  },
                  {
                    value: '1995-96',
                    label: '1995-96'
                  },
                  {
                    value: '1994-95',
                    label: '1994-95'
                  },
                  {
                    value: '1993-94',
                    label: '1993-94'
                  },
                  {
                    value: '1992-93',
                    label: '1992-93'
                  },
                  {
                    value: '1991-92',
                    label: '1991-92'
                  },
                  {
                    value: '1990-91',
                    label: '1990-91'
                  },
                  {
                    value: '1989-90',
                    label: '1989-90'
                  },
                  {
                    value: '1988-89',
                    label: '1988-89'
                  },
                  {
                    value: '1987-88',
                    label: '1987-88'
                  },
                  {
                    value: '1986-87',
                    label: '1986-87'
                  },
                  {
                    value: '1985-86',
                    label: '1985-86'
                  },
                  {
                    value: '1984-85',
                    label: '1984-85'
                  },
                  {
                    value: '1983-84',
                    label: '1983-84'
                  },
                  {
                    value: '1982-83',
                    label: '1982-83'
                  },
                  {
                    value: '1981-82',
                    label: '1981-82'
                  },
                  {
                    value: '1980-81',
                    label: '1980-81'
                  },
                  {
                    value: '1979-80',
                    label: '1979-80'
                  },
                ]}
              />
            </div>
            <div className='playerInput'>
              <h3>Year to Compare To</h3>
              <Select
                defaultValue="2023-24"
                style={{
                  width: 120,
                }}
                onChange={handleYearToCompare}
                options={[
                  {
                    value: '2023-24',
                    label: '2023-24',
                  },
                  {
                    value: '2022-23',
                    label: '2022-23',
                  },
                  {
                    value: '2021-22',
                    label: '2021-22',
                  },
                  {
                    value: '2020-21',
                    label: '2020-21'
                  },
                  {
                    value: '2019-20',
                    label: '2019-20'
                  },
                  {
                    value: '2018-19',
                    label: '2018-19'
                  },
                  {
                    value: '2017-18',
                    label: '2017-18'
                  },
                  {
                    value: '2016-17',
                    label: '2016-17'
                  },
                  {
                    value: '2015-16',
                    label: '2015-16'
                  },
                  {
                    value: '2014-15',
                    label: '2014-15'
                  },
                  {
                    value: '2013-14',
                    label: '2013-14'
                  },
                  {
                    value: '2012-13',
                    label: '2012-13'
                  },
                  {
                    value: '2011-12',
                    label: '2011-12'
                  },
                  {
                    value: '2010-11',
                    label: '2010-11'
                  },
                  {
                    value: '2009-10',
                    label: '2009-10'
                  },
                  {
                    value: '2008-09',
                    label: '2008-09'
                  },
                  {
                    value: '2007-08',
                    label: '2007-08'
                  },
                  {
                    value: '2006-07',
                    label: '2006-07'
                  },
                  {
                    value: '2005-06',
                    label: '2005-06'
                  },
                  {
                    value: '2004-05',
                    label: '2004-05'
                  },
                  {
                    value: '2003-04',
                    label: '2003-04'
                  },
                  {
                    value: '2002-03',
                    label: '2002-03'
                  },
                  {
                    value: '2001-02',
                    label: '2001-02'
                  },
                  {
                    value: '2000-01',
                    label: '2000-01'
                  },
                  {
                    value: '1999-00',
                    label: '1999-00'
                  },
                  {
                    value: '1998-99',
                    label: '1998-99'
                  },
                  {
                    value: '1997-98',
                    label: '1997-98'
                  },
                  {
                    value: '1996-97',
                    label: '1996-97'
                  },
                  {
                    value: '1995-96',
                    label: '1995-96'
                  },
                  {
                    value: '1994-95',
                    label: '1994-95'
                  },
                  {
                    value: '1993-94',
                    label: '1993-94'
                  },
                  {
                    value: '1992-93',
                    label: '1992-93'
                  },
                  {
                    value: '1991-92',
                    label: '1991-92'
                  },
                  {
                    value: '1990-91',
                    label: '1990-91'
                  },
                  {
                    value: '1989-90',
                    label: '1989-90'
                  },
                  {
                    value: '1988-89',
                    label: '1988-89'
                  },
                  {
                    value: '1987-88',
                    label: '1987-88'
                  },
                  {
                    value: '1986-87',
                    label: '1986-87'
                  },
                  {
                    value: '1985-86',
                    label: '1985-86'
                  },
                  {
                    value: '1984-85',
                    label: '1984-85'
                  },
                  {
                    value: '1983-84',
                    label: '1983-84'
                  },
                  {
                    value: '1982-83',
                    label: '1982-83'
                  },
                  {
                    value: '1981-82',
                    label: '1981-82'
                  },
                  {
                    value: '1980-81',
                    label: '1980-81'
                  },
                  {
                    value: '1979-80',
                    label: '1979-80'
                  },
                ]}
              />
            </div>
            <Button type="primary" onClick={()=>calculateData(yearOfPlayer, playerPoints, yearToCompare)}>Get Results</Button>
          </div>
          <div className='response'>
              {inflatedValue && <h3>This is equivalent to {Math.round(100*inflatedValue)/100} in {yearToCompare}</h3>}
              {inflatedValue && <h3>This is a difference of {Math.round((inflatedValue-playerPoints)*82)} points in the entire season.</h3>}
          </div>
        </div>
      );
}

export default Calculator;