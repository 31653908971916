import './App.css';
import { Routes, Route } from 'react-router-dom';

// Page imports
import Calculator from './pages/calculator';
import ScoringList from './pages/topScoringPlayers';
import Graphs from './pages/graphs';
import NotFound from './pages/notFound';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Calculator />}/>
      <Route path='/topScorers' element={<ScoringList/>}/>
      <Route path='/graphs' element={<Graphs/>}/>
      <Route path='*' element={<NotFound/>} />
    </Routes>
  )
}

export default App;
