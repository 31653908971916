import { useState } from "react";
import { NavLink } from "react-router-dom";
import { AlignRightOutlined } from "@ant-design/icons";
import { Radio } from 'antd';

// Images
import lebronJames from '../images/lebronJames.webp';
import kareem from '../images/kareem.png';
import karlMalone from '../images/karlMalone.webp';
import kobe from '../images/kobe.png';
import mj from '../images/mj.png';

const ScoringList = () => {
    const [listOrder, setListOrder] = useState(false);

    const [toggle, setToggle] = useState(false);
    const handleToggle = () => {
    if (toggle === false) {
        setToggle(true);
    } else {
        setToggle(false);
    }
    }

    return (
        <div className="scoringPage">
            <nav className={toggle ? "menuOpen":"menuClosed"}>
                <div className={toggle ? 'logoHidden':'logoText'}>NBA Point Inflation</div>
                <ul className={toggle ? "liOpen":"liClosed"}>
                    <li><NavLink to='/topScorers' onClick={()=>handleToggle()}>Check Top Scorers</NavLink></li>
                    {/* <li><NavLink to='/graphs' onClick={()=>handleToggle()}>Get Graphs</NavLink></li> */}
                    <li><NavLink to='/' onClick={()=>handleToggle()}>Calculator</NavLink></li>
                </ul>
                <div className='toggle' onClick={handleToggle}><AlignRightOutlined/></div>
            </nav>
            
            <h1>All-Time Scorers</h1>
            <div  className="scoreListRadio">
                <Radio.Group buttonStyle="solid" defaultValue="allTime" size="large">
                    <Radio.Button onChange={()=>setListOrder(false)} value="allTime">All-Time</Radio.Button>
                    <Radio.Button onChange={()=>setListOrder(true)} value="inflation">Adjusted for Inflation</Radio.Button>
                </Radio.Group>
            </div>
            {!listOrder && <div className="playerList">
                <div className="playerCard">
                    <div className="playerInfoAndPic">
                        <img src={lebronJames} alt='Lebron James'/>
                        <h2>Lebron James</h2>
                    </div>
                    <h3>40,474</h3>
                </div>
                <div className="playerCard">
                    <div className="playerInfoAndPic">
                        <img src={kareem} alt='Kareem'/>
                        <h2>Kareem Abdul-Jabbar</h2>
                    </div>
                    <h3>38,387</h3>
                </div>
                <div className="playerCard">
                    <div className="playerInfoAndPic">
                        <img src={karlMalone} alt='Karl Malone'/>
                        <h2>Karl Malone</h2>
                    </div>
                    <h3>36,928</h3>
                </div>
                <div className="playerCard">
                    <div className="playerInfoAndPic">
                        <img src={kobe} alt='Kobe'/>
                        <h2>Kobe Bryant</h2>
                    </div>
                    <h3>33,643</h3>
                </div>
                <div className="playerCard">
                    <div className="playerInfoAndPic">
                        <img src={mj} alt='Michael Jordan'/>
                        <h2>Michael Jordan</h2>
                    </div>
                    <h3>32,292</h3>
                </div>
            </div>}
            {listOrder && <div className="playerList">
                <div className="playerCard">
                    <div className="playerInfoAndPic">
                        <img src={lebronJames} alt='Lebron James'/>
                        <h2>Lebron James</h2>
                    </div>
                    <div>
                        <h3>45,012</h3>
                        <h5>+4538</h5>
                    </div>
                </div>
                <div className="playerCard">
                    <div className="playerInfoAndPic">
                        <img src={karlMalone} alt='Karl Malone'/>
                        <h2>Karl Malone</h2>
                    </div>
                    <div>
                        <h3>41,514</h3>
                        <h5>+4586</h5>
                    </div>
                </div>
                <div className="playerCard">
                    <div className="playerInfoAndPic">
                        <img src={kareem} alt='Kareem'/>
                        <h2>Kareem Abdul-Jabbar</h2>
                    </div>
                    <div>
                        <h3>39,544</h3>
                        <h5>+1157</h5>
                    </div>
                </div>
                <div className="playerCard">
                    <div className="playerInfoAndPic">
                        <img src={kobe} alt='Kobe'/>
                        <h2>Kobe Bryant</h2>
                    </div>
                    <div>
                        <h3>39,381</h3>
                        <h5>+5738</h5>
                    </div>
                </div>
                <div className="playerCard">
                    <div className="playerInfoAndPic">
                        <img src={mj} alt='Michael Jordan'/>
                        <h2>Michael Jordan</h2>
                    </div>
                    <div>
                        <h3>32,779</h3>
                        <h5>+487</h5>
                    </div>
                </div>
            </div>}
            <h6>*Points are standardized for 2023-24 season. This means points are increased to reflect the current season, which is why points are higher.*</h6>
        </div>
    )
}

export default ScoringList;